.creationBlock-body {
  margin-bottom: 50px;
}

.creationBlock-body h2 {
  font-family: 'Fauna One', sans-serif;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  padding: 0;
  margin: 0 0 7px;
}

.creationBlock-body h2 a {
  color: #015e2f;
}

.creationBlock-body h2 a:hover {
  color: #032d2c;
  text-decoration: none;
}

.creationBlock-body h3 {
  font-family: 'Bellefair', sans-serif;
  color: #5a6f62;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  padding: 0;
  margin: 10px 0 0;
}

.creationBlock-body p {
  text-align: justify;
}

.creationBlock-body .image-div {
  margin-top: 15px;
  margin-bottom: 15px;
}

.creationBlock-body .image-div a {
  margin: 0;
}

.creationBlock-body img {
  height: 100%;
  width: 100%;
}

.footer {
  bottom: 0;
  width: 100%;

  background-color: #042827;
  color: white;
  text-align: center;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Bellefair", sans-serif;
}

.footer a {
  margin: 0 8px 0 8px;
  color: #ffffff;
}

.footer a:hover, .footer a:focus {
  color: #a9c8b2;
  text-decoration: none;
}

.footer .fontAwesome {
  color: #ffffff;
}

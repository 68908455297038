.button-custom {
  background-color: #032d2c !important;
  border-color: #032d2c !important;
  border-radius: 10px !important;
  padding: 10px !important;
  margin: 0 !important;
  font-family: 'Bellefair', sans-serif !important;
  color: #FFF !important;
  font-size: 20px !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

.button-custom:hover {
  background-color: #5a6f62 !important;
  border-color: #5a6f62 !important;
}

.button-cust {
  margin: 0;
  font-family: 'Bellefair', sans-serif;
  color: #5a6f62;
  font-size: 20px;
}

.button-cust:hover {
  text-decoration: none;
  color: #032d2c;
}

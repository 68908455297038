/* ============================ */
/* negomi/react-burger-menu CSS */
/* ============================ */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 18px;
  height: 15px;
  left: 20px;
  top: 20px;
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 22px;
    left: 36px;
    top: 36px;
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 22px;
    left: 36px;
    top: 36px;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 22px;
    left: 36px;
    top: 36px;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 22px;
    left: 36px;
    top: 36px;
  }
}


/* Color/shape of burger icon bars */
.bm-burger-bars {
  /*background: #015e2f;*/
  background: #015e2f;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #032d2c;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: rgba(255, 255, 255, 0.9);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #5a6f62;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* =================================== */
/* end of negomi/react-burger-menu CSS */
/* =================================== */

/* menu logo */
.menu-logo {
  width: 35%;
  padding-bottom: 10%;
  margin: 0 auto;
}

.menu-logo:focus {
  outline: none;
}

.menu-item {
  font-family: 'Fauna One', sans-serif;
  color: rgba(255, 255, 255, 0.9);
}

.menu-item:focus, .menu-item:hover {
  font-family: 'Fauna One', sans-serif;
  color: #a9c8b2;
  text-decoration: none;
}

.separator-menu {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: rgba(255, 255, 255, 0.6);
  margin: 12px 0 12px 0;
}

.separator-menu:focus {
  outline: none;
}

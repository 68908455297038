@import url(https://fonts.googleapis.com/css?family=Raleway:400,700,800);

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background: #fff;
  color: #fffce1;
  font-family: 'Bellefair', Arial, sans-serif;
}

#app {
  height: 100%;
}

a {
  color: #4e4a46;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #c94e50;
}

main {
  height: 100%;
  overflow: auto;
}

h1 {
  font-weight: 800;
  font-size: 3.75em;
}

/* menu logo */
#logo-div {
  padding: 20px;
  margin-bottom: 30px;
}

#logo {
  width: 70%;
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  #logo {
    width: 70%;
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #logo {
    width: 70%;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #logo {
    width: 60%;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  #logo {
    width: 50%;
  }
}

.partner-body {
  color: #5a6f62;
  font-family: 'Bellefair', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  font-style: normal;
}

.partner-body .wrapper {
  text-align: center;
}

.partner-body img {
  padding: 0;
  border: 2px solid #015e2f;
  width: 30%;
  height: 30%;
  margin-bottom: 10px;
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .partner-body img {
    width: 30%;
    height: 30%;
    margin-bottom: 10px;
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .partner-body img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .partner-body img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .partner-body img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
}

.partner-body h2 {
  color: #015e2f;
  font-family: 'Fauna One', sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  /*text-transform: capitalize;*/
  font-size: 28px;
  line-height: 35px;

  padding-bottom: 15px;
}

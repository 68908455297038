.creations-details-body {
  padding-top: 5%;
  padding-bottom: 5%;
}

.creations-details-body .images {
  padding: 10px 0 10px;
}

.creations-details-body p {
  color: #5a6f62;
  font-family: 'Bellefair', sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-style: normal;
  text-align: justify;
}

.creations-details-body h1 {
  font-family: 'Fauna One', sans-serif;
  color: #032d2c;
  text-align: center;
  font-size: 40px;
  line-height: 50px;
}

.creations-details-body .separator-30 {
  height: 30px;
  margin: 0;
}

.creations-details-body .separator-15 {
  height: 15px;
  margin: 0;
}

.creations-details-body h2 {
  font-family: 'Bellefair', sans-serif;
  color: #5a6f62;
  text-align: center;
  font-size: 22px;
  line-height: 28px;
  padding-top: 39px;
  text-transform: uppercase;
}

.home-body {
  padding-top: 5%;
  padding-bottom: 5%;
}

.home-body h1 {
  color: #032d2c;
  font-family: 'Fauna One', sans-serif;
  font-size: 40px;
  line-height: 60px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;

  padding-bottom: 10px;
}

.home-body h2 {
  color: #5a6f62;
  font-family: 'Fauna One', sans-serif;
  font-size: 30px;
  line-height: 45px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;

  padding-bottom: 15px;
}

.home-body h3 {
  color: #015c2f;
  font-family: 'Bellefair', sans-serif;
  font-size: 26px;
  line-height: 35px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;

  padding-bottom: 15px;
}

.home-body .fake-block {
  height: 100px;
  width: 100%;
}

.home-body .separator-80 {
  height: 80px;
  margin: 0;
}

.home-body .separator-30 {
  height: 30px;
  margin: 0;
}

.home-body .know-how h2 {
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;
}

.home-body .know-how img {
  padding: 10px 10% 20px;
}

.home-body .know-how p {
  color: #5a6f67;
  font-family: 'Bellefair', sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-style: normal;
  font-weight: 100;
}

.home-body .we-work {
  color: #5a6f67;
  font-family: 'Fauna One', sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 100;
}

.home-body .we-work-for {
  color: #5a6f67;
  font-family: 'Bellefair', sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-style: normal;
  font-weight: 100;
}

.home-body .know-how .extra-separator {
  margin-top: 40px;
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .home-body .know-how .extra-separator {
    margin-top: 40px;
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .home-body .know-how .extra-separator {
    margin-top: 0;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .home-body .know-how .extra-separator {
    margin-top: 0;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .home-body .know-how .extra-separator {
    margin-top: 0;
  }
}

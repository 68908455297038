.contact-body {
  text-align: center;
  color: #5a6f62;
  font-family: 'Bellefair', sans-serif;
}

.contact-body h1 {
  text-align: center;
  color: #5a6f62;
  font-family: 'Fauna One', sans-serif;
  font-size: 40px;
  line-height: 60px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;

  padding-bottom: 30px;
}

.contact-body .title {
  line-height: 27px;
  font-size: 18px;
  margin: 0;
}

.contact-body .info {
  font-weight: bold;
  font-style: normal;
}

.contact-body .info a {
  font-weight: bold;
  font-style: normal;
  color: #5a6f62;
}

.contact-body .info a:hover {
  color: #032d2c;
  text-decoration: none;
}

.contact-body .separator {
  height: 50px;
  margin: 0;
}

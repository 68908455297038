.creations-body {
  padding-top: 5%;
  padding-bottom: 5%;
}

.creations-body h1 {
  text-align: center;
  color: #5a6f62;
  font-family: 'Fauna One', sans-serif;
  font-size: 40px;
  line-height: 60px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;

  padding-bottom: 30px;
}

.creations-body .separator-80 {
  height: 80px;
  margin: 0;
}

.creations-body .separator-30 {
  height: 30px;
  margin: 0;
}

.creations-body .separator {
  height: 1px;
  width: 90%;
  border-bottom: 1px solid;
  border-bottom-color: #032d2c;
  margin: 0 auto 0 auto;
}

.about-body {
  text-align: center;
  color: #5a6f62;
  font-family: 'Bellefair', sans-serif;
}

.about-body h1 {
  text-align: center;
  color: #5a6f62;
  font-family: 'Fauna One', sans-serif;
  font-size: 40px;
  line-height: 60px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;

  padding-bottom: 30px;
}

.about-body .img-fluid {
  margin-bottom: 10px;
}

.about-body p {
  margin: 22px 0 22px 0;
  text-align: justify;
  font-size: 20px;
  line-height: 25px;
  color: #5a6f62;
}

.about-body .first-paragraph {
  margin-top: 0;
}

.about-body .signature {
  font-style: italic;
  font-family: 'Bellefair', sans-serif;
  font-size: 24px;
  color: #015e2f;
}

.about-body .separator {
  height: 50px;
  margin: 0;
}

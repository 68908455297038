.partners-body {
}

.partners-body h1 {
  text-align: center;
  color: #5a6f62;
  font-family: 'Fauna One', sans-serif;
  font-size: 40px;
  line-height: 60px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;

  padding-bottom: 30px;
}

.partners-body .separator {
  height: 70px;
  margin: 0;
}
